// Consent solution, for UiO and INN (for now)

var vrtxConsents = {};

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0, len = ca.length; i < len; i++) {
    var c = ca[i];
    var cLen = c.length;
    while (c.charAt(0) == " ") c = c.substring(1, cLen);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, cLen);
  }
  return null;
}

function createCookie(name, value, days, domain, isSecure) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  if (domain) {
    expires = expires + ";domain=" + domain;
  }
  document.cookie = name + "=" + value + expires + "; path=/" + ((typeof forceSecure === "boolean" && forceSecure) || location.protocol === "https:" ? ";secure" : "");
}

function eraseCookie(name, domain) {
  createCookie(name, "", -1, domain);
}

function appendScript(cookieName) {
  var s = document.createElement("script");
  s.src = "/vrtx/decorating/resources/script/consent/" +
    cookieName.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
      return "-" + y.toLowerCase();
    }).replace(/^-/, "") + ".js";
  //document.head.appendChild(s);
  document.getElementsByTagName("head")[0].appendChild(s);
}

function checkIfGaScriptHasBeenAddedThenAdd(cookieName) {
  var scriptObserver = new MutationObserver(function (mutationRecords) {
    for (var i = 0; i < mutationRecords.length; i++) {
      var mutationRecord = mutationRecords[i];
      if (
        mutationRecord.addedNodes &&
        mutationRecord.addedNodes.length > 0 &&
        mutationRecord.addedNodes[0].outerHTML &&
        /customTracker.js"><\/script>$/.test(
          mutationRecord.addedNodes[0].outerHTML.trim()
        )
      ) {
        appendScript(cookieName);
      }
    }
  });
  scriptObserver.observe(document.head, { childList: true });
}

function essentialCookiesAppendScript(cookieName) {
  checkIfGaScriptHasBeenAddedThenAdd(cookieName);
}
function setConsentFlagsAndAppendScript(cookieName) {
  if (readCookie(cookieName)) {
    vrtxConsents[cookieName] = true;
    checkIfGaScriptHasBeenAddedThenAdd(cookieName);
  }
}

// Local tracking scripts (not many have that, so dont add JS scripts in head unnessecary)
if (/(www.inn.no|inn-test.uio.no)/.test(location.hostname)) {
  essentialCookiesAppendScript("essential");
  setConsentFlagsAndAppendScript("UsageAnalysisConsent");
  setConsentFlagsAndAppendScript("MarketingConsent");
}
if (/(www.nih.no|nih-test.uio.no)/.test(location.hostname)) {
  setConsentFlagsAndAppendScript("UsageAnalysisConsent");
}
if (/(www.hiof.no|hiof-test.uio.no)/.test(location.hostname)) {
  setConsentFlagsAndAppendScript("UsageAnalysisConsent");
}

var consentHostNames = [
  "www.nora.ai", "www.uio.no", "www.mn.uio.no",
  "www.med.uio.no", "www.hf.uio.no", "www.tf.uio.no",
  "www.sv.uio.no", "www.jus.uio.no", "www.odont.uio.no",
  "www.uv.uio.no", "www.ub.uio.no", "www.usit.uio.no",
  "www.stk.uio.no", "www.sum.uio.no", "www.nhm.uio.no",
  "www.khm.uio.no", "www.muv.uio.no", "vortex-test.uio.no", "vortex-test-int.uio.no",
  "nyweb1.uio.no", "www.nera2018.uio.no", "www.seas.uio.no",
  "www.next.uio.no", "www.smart.uio.no", "www.unirand.uio.no",
  "www.whatworks.uio.no", "www.apollon.uio.no", "www.uniforum.uio.no",
  "www.studentparlamentet.uio.no", "www.titan.uio.no", "titan-test.uio.no",
  "nyweb2.uio.no", "www.dissco.uio.no", "www.forbio.uio.no",
  "www.plantid.uio.no", "www.frisch.uio.no", "www.lifebrain.uio.no",
  "www.plato.uio.no", "www.tibet.uio.no", "www.aquagenome.uio.no",
  "www.atlast.uio.no", "www.cosmoglobe.uio.no", "www.dice.uio.no",
  "www.eu3d.uio.no", "www.finse.uio.no", "www.globus.uio.no",
  "www.greenmar.uio.no", "www.icds31.uio.no", "www.marmaed.uio.no",
  "www.metoxia.uio.no", "www.nanoheal.uio.no", "www.normer.uio.no",
  "www.scandem2021.uio.no", "www.sequencing.uio.no", "www.ssalmon.uio.no",
  "www.sustainabilitylab.uio.no", "www.sustain.uio.no", "www.nnrc.uio.no",
  "www.norcc.uio.no", "www.comorment.uio.no", "www.nalokson.uio.no",
  "www.napi.uio.no", "www.realment.uio.no", "www.rescuer.uio.no",
  "www.sand.uio.no", "www.cresco.uio.no", "www.prima.uio.no",
  "www.openscreen.uio.no", "www.opera.uio.no", "www.winprog.uio.no",
  "www.macprog.uio.no", "www.vortexkurs.uio.no", "www.vortex-demo.uio.no",
  "www.accessafrica.uio.no", "www.arche.uio.no",
  "inn-test.uio.no", "www.inn.no",
  "nih-test.uio.no", "www.nih.no",
  "hiof-test.uio.no", "www.hiof.no",
  "www.nutriome.uio.no", "www.lcbc.uio.no", "www.corehgs.uio.no",
  "www.samtalesenter.uio.no", "www.rercto.uio.no", "www.synergy-plague.org",
  "www.historiskmuseum.no", "www.vikingtidsmuseet.no"
];
// Exclude usage analysis section and cookie (the norm is to have it)
var consentHostNamesWithoutUsageAnalysisSection = [
];
// Include marketing section and cookie (the norm is to not have it)
var consentHostNamesWithMarketingSection = [
  "inn-test.uio.no", "www.inn.no",
];

window.runCookieBanner = true; // Run old uio1 / uio2 cookie banner (if have)

if (consentHostNames.indexOf(location.hostname.replace("www-int", "www")) !== -1) {
  document.addEventListener("DOMContentLoaded", (e) => {
    consentInit();
  });
  window.runCookieBanner = false;
} else {
  window.runCookieBanner = true;
}

function consentInit() {
  var consentCookieStoreLocation = null;
  // .uio hosts that should have local cookie instead of common (live host is not .uio host)
  if (/(www.inn.no|inn-test.uio.no)/.test(location.hostname) ||
      /(www.nih.no|nih-test.uio.no)/.test(location.hostname)) { // x1 testhost
    consentCookieStoreLocation = location.hostname;
  } else {
    if (/\.uio\.no$/.test(location.hostname)) {
      consentCookieStoreLocation = "uio.no";
    } else {
      consentCookieStoreLocation = location.hostname;
    }
  }

  let lang = document.getElementsByTagName("html")[0].getAttribute("lang");
  const i18nConsentAll = {
    no: {
      btnAccept: "Godta",
      btnAcceptAll: "Godta alle",
      btnDeny: "Avslå",
      langLink: "English",
      btnCustomizeOk: "Lagre samtykke",
      btnCustomizeOkNoneOptionalSelected: "Kun nødvendige",
      title: "Dette nettstedet bruker informasjons&shy;kapsler",
      settingsTitle: "Innstillinger for informasjons&shy;kapsler",
      linkSettings: "Detaljer for informasjons&shy;kapsler",
    },
    en: {
      btnAccept: "Accept",
      btnAcceptAll: "Accept all",
      btnDeny: "Deny",
      langLink: "Norwegian",
      btnCustomizeOk: "Save consent",
      btnCustomizeOkNoneOptionalSelected: "Only essential",
      title: "This webpage uses cookies",
      settingsTitle: "Cookie settings",
      linkSettings: "Read more about cookies",
    },
  };
  let i18nConsent = i18nConsentAll[lang];

  let params = new URLSearchParams(document.location.search);
  let consentSettingsParam = params.get("consent-settings");
  window.cookieNameConsentEssentials = /(www.inn.no|inn-test.uio.no)/.test(
    location.hostname
  )
    ? "PrivacyPolicyOptOutInn"
    : "PrivacyPolicyOptOut";

  if (
    !window.navigator.cookieEnabled ||
    window != top ||
    location.pathname === "/om/regelverk/personvern/personvernerklering-nett.html" ||
    location.pathname === "/english/about/regulations/privacy-declarations/privacy-policy-web.html" ||
    location.href === "https://www.khm.uio.no/english/research/collections/gjoahaven/?exhibition=true"
  ) {
    if (consentSettingsParam === "true") {
      consentCustomize("consentA", "a", lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation);
    }
  } else if (consentSettingsParam === "true") {
    consentCustomize("consentA", "a", lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation);
  } else {
    if (readCookie(cookieNameConsentEssentials) !== "yes") {
      let consentParam = params.get("consent");

      var scriptParams = document.getElementById("consent-script");
      var scriptParamsVariant = null;
      if(scriptParams && scriptParams.dataset && scriptParams.dataset["variant"]) {
        scriptParamsVariant = scriptParams.dataset["variant"];
      }

      if (consentParam != null || scriptParamsVariant != null) {
        // override
        var variant = -1;
      } else {
        var variant = 0; // A was decided chosen by UiO and INN (data said 8% more clicked yes on that one)
        // DON'T DELETE, it's used for A/B testing
        // var variantSaved = readCookie("consentVariant");
        // if(variantSaved != null) {
        //   var variant = variantSaved;
        // } else {
        //   var variant = Math.random();
        //   createCookie("consentVariant", variant, 9, consentCookieStoreLocation);
        // }
      }
      if ((variant < 0.5 && variant >= 0) || consentParam === "A" || scriptParamsVariant === "A") {
        consentMain("consentA", "a", lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation);
      } else if (variant >= 0.5 || consentParam === "B" || scriptParamsVariant === "B") {
        consentMain("consentB", "b", lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation);
      }
    }
  }
}

function consentMain(htmlFilePrefix, consentCls, lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation) {
  // Fix for mobile browser bars in the bottom, 100vh includes it (like on Samsung S9 browser)
  window.addEventListener("resize", (e) => {
    fixMobileHeight(document.querySelector(".consent"));
    fixMobileHeight(document.querySelector(".consent-settings"));
  });

  getHtml(htmlFilePrefix).then(function (html) {
    var htmlDom = document.createElement("html");
    htmlDom.innerHTML = html;

    let intro = htmlDom.querySelector(".consent-intro-" + lang);
    const elm = document.querySelector("body");
    elm.classList.add("consent-settings-opened-mobile");

    if (consentCls === "a") {
      dialogHtml = `
        <div class="consent-overlay"></div>
        <div class="consent consent-${consentCls}" role="dialog" aria-labelledby="consent-dialog-title" tabindex="0" lang="${lang}">
          <div class="consent-inner">
            <a class="consent-lang" href="/">${i18nConsent.langLink}</a>
            <h1 class="consent-title" id="consent-dialog-title">${i18nConsent.title}</h1>
            <p class="consent-main-intro-text">${intro.innerHTML}</p>
            <p class="consent-main-buttons">
              <button class="consent-accept-all button-large">${i18nConsent.btnAccept}</button>
              <button class="consent-decline-all button-large">${i18nConsent.btnDeny}</button>
            </p>
            <p><a class="consent-customize" href="/">${i18nConsent.linkSettings}</a></p>
          </div>
        </div>
      `;
    } else {
      dialogHtml = `
        <div class="consent consent-${consentCls}" role="dialog" aria-labelledby="consent-dialog-title" tabindex="0" lang="${lang}">
          <div class="consent-inner">
            <div>
              <a class="consent-lang" href="/">${i18nConsent.langLink}</a>
              <h1 class="consent-title" id="consent-dialog-title">${i18nConsent.title}</h1>
              <p class="consent-main-intro-text">${intro.innerHTML}</p>
            </div>
            <div>
              <p class="consent-main-buttons">
                <button class="consent-accept-all button-large">${i18nConsent.btnAccept}</button>
                <button class="consent-decline-all button-large">${i18nConsent.btnDeny}</button>
              </p>
              <p><a class="consent-customize" href="/">${i18nConsent.linkSettings}</a></p>
            </div>
          </div>
        </div>
      `;
    }
    const hidNavWrapper = document.getElementById("hidnav-wrapper");
    if (hidNavWrapper) {
      hidNavWrapper.insertAdjacentHTML("afterend", dialogHtml);
    } else {
      elm.insertAdjacentHTML("afterbegin", dialogHtml);
    }

    const consentDialog = document.querySelector(".consent");

    fixMobileHeight(consentDialog);

    if (consentCls === "a") {
      trapFocusInsideDialog(consentDialog, ".consent-lang", ".consent-customize", "button.hidden");
    }

    consentDialog.addEventListener("click", (e) => {
      let isConsentAcceptAll = e.target && e.target.classList.contains("consent-accept-all");
      let isConsentDeclineAll = e.target && e.target.classList.contains("consent-decline-all");
      let isConsentCustomize = e.target && e.target.classList.contains("consent-customize");
      let isConsentLang = e.target && e.target.classList.contains("consent-lang");

      let consentsObj = {};

      if (isConsentAcceptAll) {
        createCookie(cookieNameConsentEssentials, "yes", 90, consentCookieStoreLocation);
        consentsObj[cookieNameConsentEssentials] = true;

        if (consentHostNamesWithoutUsageAnalysisSection.indexOf(location.hostname.replace("www-int", "www")) === -1) { // Exclude
          createCookie("UsageAnalysisConsent", "yes", 90, consentCookieStoreLocation);
          consentsObj["UsageAnalysisConsent"] = true;
        }
        if (consentHostNamesWithMarketingSection.indexOf(location.hostname.replace("www-int", "www")) !== -1) { // Include
          createCookie("MarketingConsent", "yes", 90, consentCookieStoreLocation);
          consentsObj["MarketingConsent"] = true;
        }
        consentsObj["consent-" + consentCls.toUpperCase()] = true;

        postConsent(consentsObj, function () {
          closeAll();
          location.href = location.href.replace("?consent-settings=true", "");
        });

        e.stopPropagation();
        e.preventDefault();
      }
      if (isConsentDeclineAll) {
        createCookie(cookieNameConsentEssentials, "yes", 90, consentCookieStoreLocation);
        closeAll();
        location.href = location.href.replace("?consent-settings=true", "");

        e.stopPropagation();
        e.preventDefault();
      }
      if (isConsentCustomize) {
        consentCustomize(htmlFilePrefix, consentCls, lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation);
        e.stopPropagation();
        e.preventDefault();
      }
      if (isConsentLang) {
        if (lang === "en") {
          lang = "no";
        } else {
          lang = "en";
        }
        i18nConsent = i18nConsentAll[lang];
        intro = htmlDom.querySelector(".consent-intro-" + lang);
        document.querySelector(".consent-lang").textContent = i18nConsent.langLink;
        document.querySelector(".consent-title").innerHTML = i18nConsent.title;
        document.querySelector(".consent-main-intro-text").innerHTML = intro.innerHTML;
        document.querySelector(".consent-accept-all").textContent = i18nConsent.btnAccept;
        document.querySelector(".consent-decline-all").textContent = i18nConsent.btnDeny;
        document.querySelector(".consent-customize").innerHTML = i18nConsent.linkSettings;
        document.querySelector(".consent").setAttribute("lang", lang);

        e.stopPropagation();
        e.preventDefault();
      }
    });
  });
}

function consentCustomize(htmlFilePrefix, consentCls, lang, i18nConsent, i18nConsentAll, consentCookieStoreLocation) {
  const settings = {};
  getHtml(htmlFilePrefix + "-settings").then(function (html) {
    const consentDialog = document.querySelector(".consent");
    if (consentDialog) {
      consentDialog.remove();
    }

    var htmlDom = document.createElement("html");
    htmlDom.innerHTML = html;

    let consentFellestekst = consentCustomizeFellestekst(htmlDom, lang);

    let dialogSettingsHtml = "";
    const hasConsentOverlay = document.querySelector(".consent-overlay");
    if (!hasConsentOverlay) {
      dialogSettingsHtml += `<div class="consent-overlay"></div>`;
    }
    dialogSettingsHtml += `
      <div class="consent-settings consent-${consentCls}-settings" role="dialog" aria-labelledby="consent-dialog-title" tabindex="0" lang="${lang}">
        <div class="consent-settings-inner">
          <a class="consent-settings-lang" href="/">${i18nConsent.langLink}</a>
          <div>
            <h1 class="consent-settings-title" id="consent-dialog-title">${i18nConsent.settingsTitle}</h1>
            <div class="consent-settings-intro">${consentFellestekst.intro}</div>
          </div>
          <div class="consent-settings-type consent-settings-essential">${consentFellestekst.essential}</div>
          ${consentFellestekst.usageAnalysisHtml}
          ${consentFellestekst.marketingHtml}
          <p class="consent-settings-main-buttons">
            <button class="consent-settings-save button-large">${i18nConsent.btnCustomizeOk}</button>
            <button class="consent-settings-accept-all button-large">${i18nConsent.btnAcceptAll}</button>
          </p>
        </div>
      </div>
    `;
    const elm = document.querySelector("body");
    elm.classList.add("consent-settings-opened");

    const hidNavWrapper = document.getElementById("hidnav-wrapper");
    if (hidNavWrapper) {
      hidNavWrapper.insertAdjacentHTML("afterend", dialogSettingsHtml);
    } else {
      elm.insertAdjacentHTML("afterbegin", dialogSettingsHtml);
    }

    const consentSettingsDialog = document.querySelector(".consent-settings");

    fixMobileHeight(consentSettingsDialog);

    trapFocusInsideDialog(
      consentSettingsDialog,
      ".consent-settings-lang",
      ".consent-settings-accept-all",
      "button.hidden"
    );

    consentCustomizeSetupAfter(settings, consentFellestekst, i18nConsent);
    consentCustomizeSetupOtherCookiesRadios(
      settings,
      consentFellestekst,
      i18nConsent
    );

    consentSettingsDialog.addEventListener("click", (e) => {
      let isConsentCustomizeSave = e.target && e.target.classList.contains("consent-settings-save");
      let isConsentCustomizeAcceptAll = e.target && e.target.classList.contains("consent-settings-accept-all");
      let isConsentCustomizeLang = e.target && e.target.classList.contains("consent-settings-lang");

      if (isConsentCustomizeSave || isConsentCustomizeAcceptAll) {
        createCookie(cookieNameConsentEssentials, "yes", 90, consentCookieStoreLocation);
        var consentsObj = { cookieNameConsentEssentials: true };

        // For check if consent has changed
        var usageAnalysisCurrentConsent = readCookie("UsageAnalysisConsent");
        var marketingCurrentConsent = readCookie("MarketingConsent");
        var consentHasChanged = false;

        if (isConsentCustomizeSave) {
          if (consentFellestekst.usageAnalysisHtml != "") {
            if (document.querySelector(".consent-settings-usage-analysis li a.checked")) {
              if (usageAnalysisCurrentConsent == null) {
                consentHasChanged = true;
              }
              consentsObj["UsageAnalysisConsent"] = true;
              createCookie("UsageAnalysisConsent", "yes", 90, consentCookieStoreLocation );
            } else {
              if (usageAnalysisCurrentConsent != null) {
                consentHasChanged = true;
              }
              eraseCookie("UsageAnalysisConsent", consentCookieStoreLocation);
            }
          }
          if (consentFellestekst.marketingHtml != "") {
            if (document.querySelector(".consent-settings-marketing li a.checked")) {
              if (marketingCurrentConsent == null) {
                consentHasChanged = true;
              }
              consentsObj["MarketingConsent"] = true;
              createCookie("MarketingConsent", "yes", 90, consentCookieStoreLocation);
            } else {
              if (marketingCurrentConsent != null) {
                consentHasChanged = true;
              }
              eraseCookie("MarketingConsent", consentCookieStoreLocation);
            }
          }
        } else {
          if (consentFellestekst.usageAnalysisHtml != "") {
            consentsObj["UsageAnalysisConsent"] = true;
            if (usageAnalysisCurrentConsent == null) {
              consentHasChanged = true;
            }
            createCookie("UsageAnalysisConsent", "yes", 90, consentCookieStoreLocation);
          }
          if (consentFellestekst.marketingHtml != "") {
            consentsObj["MarketingConsent"] = true;
            if (marketingCurrentConsent == null) {
              consentHasChanged = true;
            }
            createCookie("MarketingConsent", "yes", 90, consentCookieStoreLocation);
          }
        }
        consentsObj["consent-" + consentCls.toUpperCase()] = true;

        var cbFnConsentDone = function () {
          closeAll();
          const elm = document.querySelector("body");
          elm.classList.remove("consent-settings-opened");
          elm.classList.remove("consent-settings-opened-mobile");

          location.href = location.href.replace("?consent-settings=true", "");
        };

        // Only send to consent backend service if some of the consent have changed
        // (PrivacyPolicyOptOut is not consent, essential cookies)
        if (consentHasChanged) {
          postConsent(consentsObj, cbFnConsentDone);
        } else {
          cbFnConsentDone();
        }

        e.stopPropagation();
        e.preventDefault();
      }
      if (isConsentCustomizeLang) {
        if (lang === "en") {
          lang = "no";
        } else {
          lang = "en";
        }
        i18nConsent = i18nConsentAll[lang];
        consentFellestekst = consentCustomizeFellestekst(htmlDom, lang);

        document.querySelector(".consent-settings-lang").textContent = i18nConsent.langLink;
        document.querySelector(".consent-settings-title").innerHTML = i18nConsent.settingsTitle;
        document.querySelector(".consent-settings-intro").innerHTML = consentFellestekst.intro;
        document.querySelector(".consent-settings-essential").innerHTML = consentFellestekst.essential;
        if (consentFellestekst.usageAnalysisHtml != "") {
          document.querySelector(".consent-settings-usage-analysis").innerHTML = consentFellestekst.usageAnalysisHtml;
        }
        if (consentFellestekst.marketingHtml != "") {
          document.querySelector(".consent-settings-marketing").outerHTML = consentFellestekst.marketingHtml;
        }
        document.querySelector(".consent-settings-save").textContent = i18nConsent.btnCustomizeOk;
        document.querySelector(".consent-settings-accept-all").textContent = i18nConsent.btnAcceptAll;
        document.querySelector(".consent-settings").setAttribute("lang", lang);

        consentCustomizeSetupAfter(settings, consentFellestekst, i18nConsent);
        consentCustomizeSetupOtherCookiesRadios(settings, consentFellestekst, i18nConsent);

        e.stopPropagation();
        e.preventDefault();
      }
    });
  });
}

// Fix for mobile browser bars in the bottom, 100vh includes it (like on Samsung S9 browser)
function fixMobileHeight(elm) {
  if (elm) {
    elm.removeAttribute("style");
    if (window.innerWidth < 768) {
      const diffHeight = window.innerHeight - elm.offsetHeight;
      // If calculated 100vh including the bar (which you can't see through)
      if (diffHeight < 0) {
        // 65px is how far from the top the dialog is placed (where you can see the webpage under)
        // TODO: overridable with e.g. window.configConsentDialogTop
        elm.style.height = elm.offsetHeight - (diffHeight * -1 + 65) + "px";
      }
    }
  }
}

function trapFocusInsideDialog(elm, firstSelector, lastSelector, hiddenTabStartSelector) {
  var firstFocusableElm = elm.querySelector(firstSelector);
  var lastFocusableElm = elm.querySelector(lastSelector);
  elm.focus();
  elm.addEventListener("keydown", function (e) {
    if (e.key === "Tab") {
      if (e.shiftKey) {
        /* shift + tab */
        if (document.activeElement === firstFocusableElm) {
          lastFocusableElm.focus();
          e.preventDefault();
        }
      } else {
        /* tab */
        if (document.activeElement === lastFocusableElm) {
          firstFocusableElm.focus();
          e.preventDefault();
        }
      }
    }
  });
}

function postConsent(consentsObj, cb) {
  fetch("/vrtx/__vrtx/app-services/consent", {
    method: "POST",
    body: JSON.stringify(consentsObj),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then(function (response) {
      console.log(response);
    })
    .then(function (data) {
      //$.post("/vrtx/__vrtx/app-services/consent", JSON.stringify(consentsObj)).complete((data, textStatus, xhr) =>{
      cb();
      //});
    })
    .catch((error) => console.error("Error:", error));
}

function consentCustomizeFellestekst(htmlDom, lang) {
  const intro = htmlDom.querySelector(".consent-intro-" + lang);
  const essential = htmlDom.querySelector(".consent-essential-" + lang);
  const usageAnalysis = htmlDom.querySelector(".consent-usage-analysis-" + lang);
  const marketing = htmlDom.querySelector(".consent-marketing-" + lang);

  const essentialTitle = essential.querySelector(":scope > h3:first-child");
  if (essentialTitle) essentialTitle.remove();

  let usageAnalysisHtml = "";
  if(usageAnalysis) {
    const usageAnalysisTitle = usageAnalysis.querySelector(":scope > h3:first-child");
    if (usageAnalysisTitle) usageAnalysisTitle.remove();
    usageAnalysisHtml = `<div class="consent-settings-type consent-settings-usage-analysis">${usageAnalysis.innerHTML}</div>`;
  }

  let marketingHtml = "";
  if (marketing) {
    const marketingLangTitle = marketing.querySelector(":scope > h3:first-child");
    if (marketingLangTitle) marketingLangTitle.remove();

    marketingHtml = `<div class="consent-settings-type consent-settings-marketing">${marketing.innerHTML}</div>`;
  }
  return {
    intro: intro.innerHTML,
    essential: essential.innerHTML,
    usageAnalysisHtml: usageAnalysisHtml,
    marketingHtml: marketingHtml,
  };
}

function consentCustomizeSetupAfter(settings, consentFellestekst, i18nConsent) {
  // Apply accordions
  if (typeof add2Accordions === "function") {
    add2Accordions(".consent-settings-inner", true, "h3");
  } else if (typeof addAccordions === "function") {
    addAccordions(".consent-settings-inner", true, "h3");
  }

  // Scrollable tables on mobile
  let waitALittleForScrollableTableSetup = setTimeout(() => {
    if (window.UiO2 && typeof UiO2.scrollableTablesIframes === "function") {
      UiO2.scrollableTablesIframes();
    } else if (window.UiO && typeof UiO.mobileReponsiveTable === "function") {
      UiO.mobileReponsiveTable(".consent-settings table");
    }
  }, 10);

  document.querySelector(".consent-settings-essential li a").classList.add("checked", "disabled");

  // readCookie(cookieNameConsentEssentials) &&
  if (consentFellestekst.usageAnalysisHtml != "") {
    if (readCookie("UsageAnalysisConsent") || settings["UsageAnalysisConsent"]) {
      document.querySelector(".consent-settings-usage-analysis li a").classList.add("checked");
      settings["UsageAnalysisConsent"] = true;
    } else {
      settings["UsageAnalysisConsent"] = false;
    }
  }
  if (consentFellestekst.consentMarketingHtml != "") {
    if (readCookie("MarketingConsent") || settings["MarketingConsent"]) {
      document.querySelector(".consent-settings-marketing li a").classList.add("checked");
      settings["MarketingConsent"] = true;
    } else {
      settings["MarketingConsent"] = false;
    }
  }
  changeSettingsSaveBtnText(settings, i18nConsent);
}

function consentCustomizeSetupOtherCookiesRadios(settings, consentFellestekst, i18nConsent) {
  document.querySelector(".consent-settings-essential li a").addEventListener("click", (e) => {
    e.stopPropagation();
    e.preventDefault();
  });
  if (consentFellestekst.usageAnalysisHtml != "") {
    document.querySelector(".consent-settings-usage-analysis li a").addEventListener("click", function (e) {
      this.classList.toggle("checked");
      if (this.classList.contains("checked")) {
        settings["UsageAnalysisConsent"] = true;
      } else {
        settings["UsageAnalysisConsent"] = false;
      }
      changeSettingsSaveBtnText(settings, i18nConsent);
      e.stopPropagation();
      e.preventDefault();
    });
  }
  if (consentFellestekst.marketingHtml != "") {
    document.querySelector(".consent-settings-marketing li a").addEventListener("click", function (e) {
      this.classList.toggle("checked");
      if (this.classList.contains("checked")) {
        settings["MarketingConsent"] = true;
      } else {
        settings["MarketingConsent"] = false;
      }
      changeSettingsSaveBtnText(settings, i18nConsent);
      e.stopPropagation();
      e.preventDefault();
    });
  }
}

function changeSettingsSaveBtnText(settings, i18nConsent) {
  let noneOptionalSelected = false;
  for (const key in settings) {
    if (settings[key]) {
      noneOptionalSelected = true;
      break;
    }
  }
  if (noneOptionalSelected) {
    document.querySelector(".consent-settings-save").textContent = i18nConsent.btnCustomizeOk;
  } else {
    document.querySelector(".consent-settings-save").textContent = i18nConsent.btnCustomizeOkNoneOptionalSelected;
  }
}

function closeAll() {
  const consentOverlay = document.querySelector(".consent-overlay");
  const consentDialog = document.querySelector(".consent");
  const consentSettings = document.querySelector(".consent-settings");
  if (consentOverlay) consentOverlay.remove();
  if (consentDialog) consentDialog.remove();
  if (consentSettings) consentSettings.remove();
}

async function getHtml(consentType) {
  const html = fetch("/vrtx/dist/resources/html/" + consentType + ".html")
    .then(function (response) {
      return response.text();
    })
    .catch(function (err) {
      console.log(err);
    });
  return html;
}
